import React from 'react';
import Modal from 'react-modal';

type ModalBaseProps = {
  active: boolean;
  onClose: () => void;
  innerStyle?: string;
  title?: string;
  onAfterOpen?: () => void;
  children?: React.ReactNode;
  noCloseButton?: boolean;
};

const customStyles = {
  overlay: {
    zIndex: 500,
    backgroundColor: 'rgba(33,33,33,0.6)',
    cursor: 'pointer',
    pointerEvents: 'unset',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'auto',
    height: 'auto',
    marginRight: '-50%',
    padding: 0,
    border: 0,
    borderRadius: 0,
    background: 'transparent',
    boxShadow: '10px 10px 25px 0 rgba(0, 0, 0, 0.08)',
    color: '#333',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
  },
} as const;

export function ContentModalBase({
  active,
  onClose,
  onAfterOpen,
  title,
  children,
  noCloseButton,
  innerStyle,
}: ModalBaseProps) {
  return (
    <Modal
      isOpen={active}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      style={customStyles}
      ariaHideApp={false}
    >
      <div
        className={`modal-inner-base ${innerStyle}`}
      >
        <div className="flex justify-between mb-20 md:mb-24">
          {title && (
            <h2
              className="text-bodyM font-bold text-black md:text-bodyL"
            >
              {title}
            </h2>
          )}
          {!noCloseButton && (
            <button
              type="button"
              onClick={onClose}
              aria-label="close modal"
            >
              <img
                src="/images/ico-close.svg"
                alt="close modal"
                className="w-24 h-24"
              />
            </button>
          )}
        </div>
        <div className="flex flex-col h-full">{children}</div>
      </div>
    </Modal>
  );
}
