import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import styles from 'components/typography/landing.module.css';

export const AllInOneBox = ({ pathLang }: { pathLang: string }) => {
  const { t } = useTranslation('landing');

  const subheadingText = t('AllInOneBox.headline2', { lng: pathLang });
  const headingText = t('AllInOneBox.headline1', { lng: pathLang });
  const bodyText = t('AllInOneBox.body', { lng: pathLang });
  const formattedBodyText = bodyText.replaceAll('<li>', '<li><div class="bullet">•</div>');

  const headline2LgMbStyle = pathLang === 'ko' ? 'lg:mb-[40px]' : 'lg:mb-[24px]';

  return (
    <div
      className={`w-full mt-[80px] py-[56px] bg-lowEmp
        md:mt-[96px] md:py-[64px] lg:mt-[120px] lg:py-[80px]`}>
      <div
        className={`w-full mx-auto flex items-center flex-col
          md:w-[592px] lg:w-[1064px] lg:flex-row`}
      >
        <Image
          src="/images/landing2/ill-products.svg"
          width={262}
          height={216}
          alt="diagram of three products connected by one thread"
        />
        <div className="mt-[40px] md:mt-[48px] lg:mt-0 lg:ml-[56px]">
          <h3
            className={`${styles.headline3} text-center
            mb-[12px] lg:mb-[20px] lg:text-left`}
            dangerouslySetInnerHTML={{ __html: subheadingText }}
          />
          <h2
            className={`${styles.headline2} text-center mb-[24px]
            ${headline2LgMbStyle} lg:text-left`}
            dangerouslySetInnerHTML={{ __html: headingText }}
          />
          <div
            className={`text-black text-bodyS text-center leading-normal
              [&_li]:block [&_.bullet]:mr-[6px] [&_.bullet]:inline-block
              lg:text-bodyL lg:text-left lg:[&_li]:flex lg:[&_.bullet]:mr-[10px]`}
            dangerouslySetInnerHTML={{ __html: formattedBodyText }}
          />
        </div>
      </div>
    </div>
  );
};
