import { useTranslation } from 'react-i18next';
import styles from 'components/typography/landing.module.css';

export const FAQBox = ({ pathLang }: { pathLang: string }) => {
  const { t } = useTranslation('landing');

  const heading = t('FAQBox.title', { lng: pathLang });

  const faqItemArr = [
    {
      title: t('FAQBox.item-0-title', { lng: pathLang }),
      body: t('FAQBox.item-0-body', { lng: pathLang }),
    },
    {
      title: t('FAQBox.item-1-title', { lng: pathLang }),
      body: t('FAQBox.item-1-body', { lng: pathLang }),
    },
    {
      title: t('FAQBox.item-2-title', { lng: pathLang }),
      body: t('FAQBox.item-2-body', { lng: pathLang }),
    },
    {
      title: t('FAQBox.item-3-title', { lng: pathLang }),
      body: t('FAQBox.item-3-body', { lng: pathLang }),
    },
    {
      title: t('FAQBox.item-4-title', { lng: pathLang }),
      body: t('FAQBox.item-4-body', { lng: pathLang }),
    },
  ];

  return (
    <div
      className={`my-[80px] flex flex-col justify-between
        md:my-[96px] lg:my-[120px] lg:flex-row`}
    >
      <div>
        <h3
          className={`${styles.headline3} mb-[8px] text-center
            md:mb-[12px] lg:mb-[20px] lg:text-left`}
        >
          FAQ
        </h3>
        <h2
          dangerouslySetInnerHTML={{ __html: heading }}
          className={`${styles.headline2} mb-[24px] text-center
          md:mb-[48px] lg:mb-0 lg:text-left`}
        />
      </div>
      <div className="flex flex-col items-center">
        {faqItemArr.map(item => {
          const wordBreakStyle = ['ko', 'en', 'es'].includes(pathLang) ? 'break-keep' : 'break-all';
          return (
            <div
              key={item.title}
              className={`w-full p-[16px] mb-[16px] text-bodyS border rounded-sm
                border-gray  ${wordBreakStyle}
                md:rounded-md lg:w-[722px] lg:p-[20px] lg:mb-[24px]
                lg:text-bodyM lg:rounded-lg last-of-type:mb-0`}
            >
              <h4
                dangerouslySetInnerHTML={{ __html: item.title }}
                className={`mb-[8px] text-bodyM font-bold leading-normal
                  md:mb-[12px] lg:mb-[16px] lg:text-subhead`}
              />
              <p
                dangerouslySetInnerHTML={{ __html: item.body }}
                className="[&_a]:underline"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
