import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { Player } from '@lottiefiles/react-lottie-player';
import { SocialLoginButton } from 'components/buttons';
import styles from 'components/typography/landing.module.css';

export const SimulationBox = ({ pathLang }: { pathLang: string }) => {
  const [browser, setBrowser] = useState('');
  const [, setIsShowInAppBrowserNotice] = useState(false);

  const { t } = useTranslation('landing');

  const headingText = t('SimulationBox.heading', { lng: pathLang });
  const subheadingText = t('SimulationBox.subHeading', { lng: pathLang });
  const ctaText = t('SimulationBox.CTA', { lng: pathLang });
  const ctaGoogleText = t('SimulationBox.googleCta', { lng: pathLang });
  const ctaKakaoText = t('SimulationBox.kakaoCta', { lng: pathLang });
  const caption = t('SimulationBox.caption', { lng: pathLang });

  const videoStyleText = `w-[312px] h-[312px] rounded-sm shadow-cardLg overflow-hidden
  md:w-[360px] md:h-[360px] md:rounded-md lg:w-[480px] lg:h-[480px] lg:rounded-lg`;

  useEffect(() => {
    const { userAgent } = navigator;
    // setBrowser(userAgent);
    if (userAgent.includes('NAVER(inapp;')) {
      setBrowser('naver');
    } else if (userAgent.includes('IE')) {
      setBrowser('ie');
    } else if (userAgent.includes('Edg/')) {
      setBrowser('edge');
    } else if (userAgent.includes('OPR/') || userAgent.includes('Opera')) {
      setBrowser('opera');
    } else if (userAgent.includes('Firefox/') || userAgent.includes('Gecko/')) {
      setBrowser('firefox');
    } else if (userAgent.includes('Whale/')) {
      setBrowser('whale');
    } else if (userAgent.includes('Chrome/') || userAgent.includes('CriOS/')) {
      setBrowser('chrome');
    } else if (userAgent.includes('iPhone') || userAgent.includes('Macintosh') || userAgent.includes('iPad')) {
      setBrowser('safari');
    }
  }, []);

  return (
    <div
      className={`mt-[32px] flex flex-col justify-between items-center
        md:mt-[40px] lg:mt-[80px] lg:flex-row`}
    >
      {/* Text & Buttons */}
      <div
        className={`w-full mb-[56px] flex flex-col items-center
          md:mb-[64px] lg:w-fit lg:mb-0 lg:items-start`}
      >
        {/* Heading Text */}
        <h1
          dangerouslySetInnerHTML={{ __html: headingText }}
          className={`${styles.headline1} mb-[12px] text-center
          md:mb-[16px] lg:mb-[20px] lg:text-left`}
        />
        {/* Subheading Text */}
        <h4
          dangerouslySetInnerHTML={{
            __html: subheadingText.replaceAll('<li>', '<li><span class="bullet">•</span>'),
          }}
          className={`mb-[24px] text-bodyS leading-normal font-medium text-center
          [&_li]:block [&_.bullet]:inline-block [&_.bullet]:mr-[8px]
          md:mb-[32px] md:text-bodyM
          lg:mb-[40px] lg:text-bodyL lg:text-left lg:[&_li]:flex
          lg:[&_.bullet]:mr-[10px] lg:[&_.bullet]:h-full lg:[&_.bullet]:inline-block`}
        />
        {/* Button Row */}
        <div
          className={`w-full mb-[12px] flex flex-col-reverse items-center
            justify-center
            md:w-[416px] md:mb-[16px] md:flex-row md:justify-between
            lg:w-[464px] lg:mb-[20px]`}
        >
          <Link
            href="/auth/sign-up"
            className={`btn btn-normal w-full h-[40px] text-bodyS rounded-sm 
              md:w-[200px] md:rounded-md lg:w-[224px] lg:h-[48px] lg:text-bodyM`}
          >
            {ctaText}
          </Link>
          <SocialLoginButton
            isSignUp
            type={browser === 'naver' ? 'Kakao' : 'Google'}
            customLabel={browser === 'naver' ? ctaKakaoText : ctaGoogleText}
            setIsShowInAppBrowserNotice={setIsShowInAppBrowserNotice}
            isLandingPage
            marginBottomMb={12}
          />
        </div>
        {/* Caption Row */}
        <div className="flex row items-center justify-center lg:justify-start">
          <Image
            src="/images/ico-check.svg"
            priority
            width={16}
            height={16}
            alt="tick icon"
          />
          <p
            className={`ml-[4px] text-captionM leading-normal
              md:text-captionL lg:text-bodyS`}
          >
            {caption}
          </p>
        </div>
      </div>
      {/* Video */}
      {browser === 'safari' ? (
        <video autoPlay loop playsInline muted className={videoStyleText}>
          <source
            src={`/motions/landing_${pathLang}.mp4`}
            type="video/mp4"
          />
        </video>
      ) : (
        <div className={videoStyleText}>
          <Player
            autoplay
            loop
            src={`/motions/landing_${pathLang}.json`}
          />
        </div>
      )}
    </div>
  );
};
