import styled from 'styled-components';
import { COLOR } from 'constants/COLOR';
import { FONT_SIZE } from 'constants/FONT_SIZE';

export const BodyText = styled.p`
  color: ${COLOR.BLACK};
  font-size: ${FONT_SIZE.bodyL}px;
  font-weight: normal;
  line-height: 1.5;

  ${({ theme }) =>
    theme.media.M(`
      font-size: ${FONT_SIZE.bodyM}px;
  `)}
  ${({ theme }) =>
    theme.media.S(`
      font-size: ${FONT_SIZE.bodyS}px;
  `)}
`;
