import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import Link from 'next/link';
import styles from 'components/typography/landing.module.css';
import { SocialLoginButton } from 'components/buttons';

export const SignUpBox = ({ pathLang }: { pathLang: string }) => {
  const [isShowInAppBrowserNotice, setIsShowInAppBrowserNotice] = useState(false);

  const { t } = useTranslation('landing');

  const titleText = t('SignUpBox.title', { lng: pathLang });
  const descText = t('SignUpBox.desc', { lng: pathLang });

  const signUpBtnText = t('SignUpBox.signUpBtn', { lng: pathLang });
  const googleSignUpBtnText = t('SignUpBox.googleSignUpBtn', { lng: pathLang });

  const captionText = t('SignUpBox.caption', { lng: pathLang });

  return (
    <div
      className={`flex flex-col items-center mb-[80px]
        md:mb-[96px] lg:mb-[120px]`}
    >
      <h2
        dangerouslySetInnerHTML={{ __html: titleText }}
        className={`leading-normal text-black font-bold mb-[12px] text-subhead text-center
          md:mb-[16px] md:text-headingS lg:text-headingM`}
      />
      <p
        dangerouslySetInnerHTML={{ __html: descText }}
        className={`${styles.signUpBody}`}
      />
      <div
        className={`w-full mt-[24px] mb-[12px] flex flex-col-reverse
          justify-between
          md:w-[416px] md:mt-[32px] md:mb-[16px] md:flex-row
          lg:w-[464px] lg:mt-[40px] lg:mb-[20px]`}
      >
        <Link
          href="/auth/sign-up"
          className="btn btn-normal w-full md:w-[200px] lg:w-[224px] lg:h-[48px]"
        >
          {signUpBtnText}
        </Link>
        <SocialLoginButton
          isSignUp
          type="Google"
          customLabel={googleSignUpBtnText}
          setIsShowInAppBrowserNotice={setIsShowInAppBrowserNotice}
          isLandingPage
          marginBottomMb={12}
        />
      </div>
      <div className="flex flex-row">
        <Image src="/images/ico-check.svg" priority width={16} height={16} alt="tick icon" />
        <p
          className={`ml-[4px] text-captionM leading-normal
            md:text-captionL lg:text-bodyS`}
        >
          {captionText}
        </p>
      </div>
    </div>
  );
};
