import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { getBlogLink } from 'utils/getLangPath';

export const BlogBox = ({ pathLang }: { pathLang: string }) => {
  const { t } = useTranslation('landing');

  const headingText = t('BlogBox.heading', { lng: pathLang });
  const ctaLink = getBlogLink(pathLang);
  const ctaText = t('BlogBox.ctaText', { lng: pathLang });

  const headingMdTextStyle = pathLang === 'en'
    ? 'md:text-bodyM'
    : 'md:text-bodyL';

  const headingMdMarginTopStyle = pathLang === 'es' ? 'md:h-full' : 'md:h-fit';

  let linkBtnMbMarginStyle = '';
  switch (pathLang) {
    case 'ko':
      linkBtnMbMarginStyle = 'md:mt-[44px]';
      break;
    case 'en':
      linkBtnMbMarginStyle = 'md:mt-[36px]';
      break;
    case 'es':
      linkBtnMbMarginStyle = 'md:mt-[30px]';
      break;
    default:
      linkBtnMbMarginStyle = 'md:mt-[41px]';
      break;
  }

  return (
    <div
      className={`h-[332px] my-[80px] p-[16px] flex flex-col justify-between
        items-center gap-[8px] rounded-sm border border-outline bg-surface
        md:h-[192px] md:my-[96px] md:flex-row md:gap-[10px] md:rounded-md
        lg:h-[212px] lg:my-[120px] lg:p-[20px] lg:gap-[8px] lg:rounded-lg`}
    >
      {/* Image */}
      <div
        className={`w-full h-[154px] block relative text-center object-contain
          md:w-[308px] md:h-[160px] lg:w-[668px] lg:h-[172px]`}
      >
        <Image className="L_only" alt="go to blog" src="/images/ill-blog-pc@3x.png" fill draggable={false} />
        <Image className="M_only" alt="go to blog" src="/images/ill-blog-tb@3x.png" fill draggable={false} />
        <Image className="S_only" alt="go to blog" src="/images/ill-blog-mb@3x.png" fill draggable={false} />
      </div>
      {/* Text & Link */}
      <div
        className={`w-full h-[108px] mt-[8px] flex flex-col justify-end
          items-center text-bodyM font-medium leading-normal text-center
          md:w-[236px] ${headingMdMarginTopStyle} ${headingMdTextStyle} md:mt-0
          lg:w-[336px] lg:h-full lg:text-subhead`}
      >
        <span dangerouslySetInnerHTML={{ __html: headingText }} />
        <Link
          href={ctaLink}
          target="_blank"
          className={`btn btn-line w-[278px] mt-[20px] rounded-sm
            md:w-[236px] md:py-[10px] md:rounded-md ${linkBtnMbMarginStyle}
            lg:w-[336px] lg:h-[48px] lg:mt-[40px] lg:rounded-lg lg:text-bodyL`}
        >
          {ctaText}
        </Link>
      </div>
    </div>
  );
};
