import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { RebrandRedirectModal } from 'organisms/modal/RebrandRedirectModal';
import { getPathLang } from 'utils/getPathLang';
import { TrustedBox } from 'views/landing/organisms/TrustedBox';
import { BlogBox } from 'views/landing/organisms/BlogBox';
import { SimulationBox } from './SimulationBox';
import { CompetitorBox } from './CompetitorBox';
import { AllInOneBox } from './AllInOneBox';
import { ChatGPTAltBox } from './ChatGPTAltBox';
import { UserReviewBox } from './UserReviewBox';
import { FAQBox } from './FAQBox';
import { SignUpBox } from './SignUpBox';

export const LandingMainContents = () => {
  const router = useRouter();
  const { redirect } = router.query;
  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const innerWrapStyle = 'w-[312px] mx-auto md:w-[592px] lg:w-[1064px]';

  const pathLang = getPathLang(router.pathname);
  // useEffect set setShowRedirectModal(true)
  // if query redirect = true
  useEffect(() => {
    if (redirect) {
      setShowRedirectModal(true);
    }
  }, [redirect]);

  return (
    <div
      className="w-full pt-[48px] flex flex-col bg-background md:pt-[64px]"
    >
      <div className={innerWrapStyle}>
        <RebrandRedirectModal
          active={showRedirectModal}
          onClose={() => {
            setShowRedirectModal(false);
            return router.replace(router.pathname);
          }}
        />
        <SimulationBox pathLang={pathLang} />
        <TrustedBox pathLang={pathLang} />
        <CompetitorBox pathLang={pathLang} />
      </div>
      <AllInOneBox pathLang={pathLang} />
      <div className={innerWrapStyle}>
        <ChatGPTAltBox pathLang={pathLang} />
      </div>
      <UserReviewBox pathLang={pathLang} />
      <div className={innerWrapStyle}>
        <FAQBox pathLang={pathLang} />
        <BlogBox pathLang={pathLang} />
        <SignUpBox pathLang={pathLang} />
      </div>
    </div>
  );
};

export default LandingMainContents;
