import { LandingLayout } from 'components/layouts';
import { LandingMainContents } from './organisms/LandingMainContents';

export const LandingPage = () => {
  return (
    <LandingLayout>
      <LandingMainContents />
    </LandingLayout>
  );
};
