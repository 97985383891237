import { ModalBase } from 'components/modal';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

type RebrandRedirectModalProps = {
  active: boolean;
  onClose: () => void;
};

export const RebrandRedirectModal = ({ active, onClose }: RebrandRedirectModalProps) => {
  const router = useRouter();
  const { t } = useTranslation('landing');

  const heading = t('RebrandRedirectModal.header');
  const desc = t('RebrandRedirectModal.desc');
  const closeBtn = t('RebrandRedirectModal.closeBtn');
  const signInBtn = t('RebrandRedirectModal.signInBtn');

  return (
    <ModalBase active={active} onClose={onClose} innerStyle="w-312 md:w-512">
      <div className="flex flex-col p-24 text-center">
        <img
          className="w-[264px] md:w-[464px] h-auto"
          src="/images/landing2/ill-rebrand.svg"
          alt="illustration of a huge present box, with a girl to the left holding a sign that says 'engram' and a boy to the right looking happy and pointing to the present box."
          draggable={false}
        />
        <h1
          dangerouslySetInnerHTML={{ __html: heading }}
          className={`mt-16 mb-[4px] text-black text-bodyM font-bold
            leading-normal
            md:text-headingS md:mb-8`}
        />
        <div
          dangerouslySetInnerHTML={{ __html: desc }}
          className={`mb-[20px] text-bodyS text-darkGray leading-normal break-keep
            md:mb-24 md:text-bodyM`}
        />
        <div className="w-full flex justify-end">
          <button
            type="button"
            className={`btn btn-text text-disabled w-[88px] h-40 mr-8
              md:w-[128px] md:h-48 md:mr-12`}
            onClick={onClose}
          >
            {closeBtn}
          </button>
          <button
            type="button"
            className={`btn btn-normal w-[88px] h-40
              md:w-[128px] md:h-48`}
            onClick={() => {
              router.replace(router.pathname);
              return router.push('/auth/sign-in');
            }}
          >
            {signInBtn}
          </button>
        </div>
      </div>
    </ModalBase>
  );
};
