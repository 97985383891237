import React from 'react';
import Modal from 'react-modal';
import { COLOR } from 'constants/COLOR';

type ModalBaseProps = {
  active: boolean;
  onClose: () => void;
  onAfterOpen?: () => void;
  children?: React.ReactNode;
  innerStyle?: string;
  backgroundClickNotClose?: boolean;
};

const customStyles = {
  overlay: {
    zIndex: 500,
    backgroundColor: 'rgba(33,33,33,0.6)',
    cursor: 'pointer',
    pointerEvents: 'unset',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'auto',
    height: 'auto',
    marginRight: '-50%',
    padding: 0,
    border: 0,
    borderRadius: 0,
    background: 'transparent',
    boxShadow: '10px 10px 25px 0 rgba(0, 0, 0, 0.08)',
    color: COLOR.BLACK,
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
  },
} as const;

const customNoBackgroundCloseStyles = {
  overlay: {
    zIndex: 500,
    backgroundColor: 'rgba(33,33,33,0.6)',
    pointerEvents: 'unset',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'auto',
    height: 'auto',
    marginRight: '-50%',
    padding: 0,
    border: 0,
    borderRadius: 0,
    background: 'transparent',
    boxShadow: '10px 10px 25px 0 rgba(0, 0, 0, 0.08)',
    color: COLOR.BLACK,
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
  },
} as const;

export const ModalBase = ({
  active,
  onClose,
  onAfterOpen,
  children,
  innerStyle,
  backgroundClickNotClose,
}: ModalBaseProps) => {
  return (
    <Modal
      isOpen={active}
      onAfterOpen={onAfterOpen}
      onRequestClose={!backgroundClickNotClose ? onClose : () => {}}
      contentLabel="Example Modal"
      style={!backgroundClickNotClose ? customStyles : customNoBackgroundCloseStyles}
      ariaHideApp={false}
    >
      <div className={`modal-inner-base-lg ${innerStyle}`}>
        {children}
      </div>
    </Modal>
  );
};
